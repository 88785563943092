import 'bootstrap';
import 'jasny-bootstrap';

import '@fortawesome/fontawesome-free/js/all.js';
//import '@fortawesome/fontawesome-free';
//require('@fortawesome/fontawesome-free')

//import 'material-design-icons';

import '@fancyapps/fancybox';
$.fancybox.defaults.loop = true;
$.fancybox.defaults.infobar = true;

//jQuery.noConflict();

(function($) {

    $(document).ready(function() {

        /*
        *  J A S N Y Bootstrap: Burger Animation
        *  https://codepen.io/designcouch/pen/Atyop
        */
        $('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').click(function(){
            $(this).toggleClass('open');
        });

        /*
        * http://www.jasny.net/bootstrap/
        */
        //$("#myNavmenu").offcanvas()                        // initialized with defaults
        //$("#myNavmenu").offcanvas({ autohide: false })       // initialized with no autohide
        //$("#myNavmenu").offcanvas('show')                 // initializes and invokes show immediately</p>

    }); // ready END

    /*
    *  C A R O U S E L // Bootstrap 4
    *  https://codepen.io/designcouch/pen/Atyop
    */

    $('#Carousel').carousel({
        interval: 5000
    })

    // Change the transition time
    jQuery.fn.carousel.Constructor.TRANSITION_DURATION = 200 // 0.2 seconds

    /*
    * http://fezvrasta.github.io/bootstrap-material-design/#getting-started
    */
    //$.material.init();

    /*
     *   D O W N L O A D S  // Clicksble Row
     */
    $(".clickable-row").click(function() {
        window.document.location = $(this).data("href");
    });

    /*  C O N T A C T F O R M
   *
   *   Adding link a href to an element using css
   *   http://jsfiddle.net/r5uWX/1/
   */

    $('#Form_ContactForm_PrivacyPolicy_Holder label').each(function() {
        var link = $(this).html();
        $(this).contents().wrap('<a href="/datenschutz"></a>');
    });


    /*
     *    T O  T O P  //  »Scroll Back To Top«-Button mit jQuery
     *    Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.
     */

    var back_to_top_button = ['<a href="#top" class="back-to-top"><i class="material-icons">arrow_upward</i></a>'].join("");
    $("body").append(back_to_top_button);

    // Der Button wird ausgeblendet
    $(".back-to-top").hide();

    // Funktion für das Scroll-Verhalten
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) { // Wenn 100 Pixel gescrolled wurde
                $('.back-to-top').fadeIn();
            } else {
                $('.back-to-top').fadeOut();
            }
        });

        $('.back-to-top').click(function () { // Klick auf den Button
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });

/*
    (function($) {
        $("#OptOutWrapper").on("click", "a#deactivateGoogleAnalytics", gaOptout);
    }(jQuery));
*/

}(jQuery));